import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~3],
		"/agent": [~4],
		"/dashboard": [5,[2]],
		"/dashboard/agents": [~6,[2]],
		"/dashboard/feedback": [~7,[2]],
		"/dashboard/groups": [~8,[2]],
		"/dashboard/recordings": [~9,[2]],
		"/logging-in": [10]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';